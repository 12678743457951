import React, { Component } from "react";
import "../css/styles.css";
import "../css/alladmin.css";

export default function success() {
return (
<div className="homereset_content">
<div className="formcontainerpf card mb-3">
        <div className="header1"> รหัสผ่านของคุณเปลี่ยนแล้ว</div>
          <div className="mb-3">
          <p className="success">คุณสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่ของคุณ</p>
          </div>
      </div>
      </div>
);
    
}